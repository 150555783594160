a.title {
    font-family: "NanumSquareRound", sans-serif;
    font-size: 1.25rem;
    font-weight: 800;
    line-height: 1;
    color: #04253a;
    align-self: center;
}

/*
 * Just a quick hamburger
 */
.navbar-toggler,
.navbar-toggler:focus {
    outline: none;
    outline: 0;
}
.navbar-toggler span {
    position: relative;
    display: block;
    width: 20px;
    height: 3px;
    margin-top: 4px;

    background: #212121;
    border-radius: 4px;

    z-index: 1;

    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
        background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
}
.navbar-toggler span:first-child {
    margin-top: 0;
}
.navbar-toggler span:first-child {
    transform: rotate(45deg) translate(5px, 4px);
}
.navbar-toggler span:nth-child(3) {
    transform: rotate(-45deg) translate(6px, -5px);
}
.navbar-toggler span:nth-child(2) {
    opacity: 0;
}
.navbar-toggler.collapsed span:first-child {
    transform: none;
}
.navbar-toggler.collapsed span:nth-child(3) {
    transform: none;
}
.navbar-toggler.collapsed span:nth-child(2) {
    opacity: 1;
}

.navbar-toggler-icon {
    width: 1.25em;
    height: 1.25em;
}
.navbar-light .navbar-toggler {
    padding: 0.4rem 0.35rem;
}
